.projectsContainer {
  padding-top: 70px;
  padding-bottom: 100px;
  width: 100%;
  margin: auto;
}

.lightProj {
  background-color: var(--bg-color-light);
  color: #3d3834;
}

.darkProj {
  background-color: var(--bg-color-dark);
}

.projectsContainerH2 {
  font-size: 40px;
  padding-bottom: 50px;
  margin-bottom: 10px;
}

.projectsContainerH2Dark {
  color: var(--text-color-dark-orange);
}

.projectsContainerH2Light {
  color: var(--text-color-light-orange);
}

.projectsImagesContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 70px;
  flex-wrap: wrap;
  width: 75%;
  margin: auto;
}
.projectsImagesContainer div {
  transition: 0.2s;
  border-radius: 20px;
  margin-bottom: 30px;
}

.projectsImagesContainer div a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.projectName {
  font-size: 18px;
  margin-bottom: 40px;
  width: 100%;
  padding: 7px 0px;
  margin-top: 0;
  margin-bottom: 0;
}

.projectNameDark {
  background-color: var(--bg-color-dark-2);
  color: var(--text-color-dark-green);
}

.projectNameLight {
  background-color: var(--bg-color-light-2);
  color: var(--text-color-light-green);
}

.divPlaceholder {
  max-width: 900px;
  min-width: 270px;
  width: 80%;
  margin: 0 auto;
  border-radius: 20px;
  margin-top: 40px;
  transition: 0.3s;
}

.divPlaceholder2 {
  max-width: 900px;
  min-width: 270px;
  width: 80%;
  margin: 0 auto;
  border-radius: 20px;
  margin-top: 40px;
  /* transition: 0.3s; */
}

.lightAPIProj {
  background-color: var(--bg-color-light-2);
}

.darkAPIProj {
  background-color: var(--bg-color-dark-2);
}

.projectImage {
  width: 200px;
}

@media only screen and (min-width: 350px) {
  .projectImage {
    width: 300px;
    padding: 0;
    transition: 0.3s;
  }

  .projectName {
    width: 100%;
    font-size: 20px;
    padding: 10px 0px;
  }
}

@media only screen and (min-width: 700px) {
  .projectImage {
    width: 300px;
    padding: 0;
    transition: 0.3s;
  }

  .projectImageDark {
    filter: saturate(0) brightness(0.7);
  }

  .projectImageLight {
    filter: saturate(0) brightness(1);
  }

  .projectName {
    width: 100%;
    font-size: 20px;
    padding: 10px 0px;
  }

  .projectsImagesContainer div:hover img {
    filter: saturate(1) brightness(1);
  }

  .projectsImagesContainer div:hover .projectNameDark {
    color: var(--text-color-dark-orange);
  }
  .projectsImagesContainer div:hover .projectNameLight {
    color: var(--text-color-light-orange);
  }
}
