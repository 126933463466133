.generalContainer {
  position: relative;
}

.imgQman {
  width: 15%;
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.formContainer {
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 50px;
}

.factBtn {
  background-color: transparent;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 20px;
  text-transform: uppercase;
  transition: 0.3s;
}

.factBtnDark {
  color: var(--text-color-dark-green);
  background-color: #aab83e00;
  border: 2px solid var(--text-color-dark-green);
}
.factBtnDark:hover {
  color: #34353a;
  background-color: var(--text-color-dark-green);
  border: 2px solid var(--text-color-dark-green);
  cursor: pointer;
}

.factBtnLight {
  color: var(--text-color-light-green);
  background-color: #90c90000;
  border: 2px solid var(--text-color-light-green);
}

.factBtnLight:hover {
  color: rgb(255, 255, 255);
  background-color: var(--text-color-light-green);
  border: 2px solid var(--text-color-light-green);
  cursor: pointer;
}

.titleContainer {
  margin-left: 40px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
}
.title {
  font-size: 30px;
}

.titleDark {
  color: var(--text-color-dark-orange);
}

.titleLight {
  color: var(--text-color-light-orange);
}

.questionsContainer {
  max-width: 630px;
  margin-left: 40px;
  display: flex;
  align-items: center;
}

.formQuestion {
  margin-top: 0;
  font-size: 50px;
}

.formQuestionDark {
  color: var(--text-color-dark-green);
}

.formQuestionLight {
  color: var(--text-color-light-green);
}

.formQuestion2 {
  margin-top: 0;
  font-size: 50px;
  margin-left: 10px;
}

.formQuestion2Dark {
  color: var(--text-color-dark-grey);
}

.formQuestion2Light {
  color: var(--text-color-light-grey);
}

.formQuestion,
.formQuestion2 {
  text-transform: uppercase;
  text-align: left;
  font-family: "MontserratLight";
}

.formQuestion2 span {
  font-family: "MontserratSemiBold";
}

.resultTextContainer {
  padding-bottom: 40px;
  max-width: 730px;
  margin-left: 40px;
  text-align: left;
  text-align: justify;
}

.resultTextContainerDark p {
  color: var(--text-color-dark-orange);
}

.resultTextContainerLight p {
  color: var(--text-color-light-grey);
}

@media only screen and (min-width: 1100px) {
  .questionsContainer {
    max-width: 830px;
  }

  .resultTextContainer {
    max-width: 730px;
  }
}
@media only screen and (max-width: 1100px) {
  .questionsContainer {
    max-width: 730px;
  }

  .resultTextContainer {
    max-width: 700px;
  }
}

@media only screen and (max-width: 1040px) {
  .resultTextContainer {
    max-width: 650px;
  }
}

@media only screen and (max-width: 970px) {
  .generalContainer img {
    margin-top: 30px;
  }
  .imgQman {
    position: relative;
    right: 0;
    width: 60px;
  }

  .formQuestion {
    text-align: center;
    margin-bottom: 0px;
  }

  .formQuestion2 {
    margin-top: 0;
    text-align: center;
  }

  .titleContainer {
    text-align: center;
    margin: 20px 0;
  }

  .questionsContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .formContainer {
    margin-left: 15px;
    margin-right: 15px;
    justify-content: center;
    margin-bottom: 30px;
  }

  .formContainer input,
  .factBtn {
    margin-bottom: 20px;
  }
  .factBtn {
    margin-left: 10px;
  }

  .resultTextContainer {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 750px) {
  .imgQman {
    top: 0;
    margin: 0;
    width: 60px;
  }
  .formQuestion {
    margin-top: 0px;
  }

  .formContainer {
    flex-direction: column;
  }

  .factBtn {
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {
  .resultTextContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}
