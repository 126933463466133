@font-face {
  font-family: Montserrat;
  src: url("./Font/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: MontserratSemiBold;
  src: url("./Font/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: MontserratMedium;
  src: url("./Font/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: MontserratExtraLight;
  src: url("./Font/Montserrat/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: MontserratSemiBold;
  src: url("./Font/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: MontserratLight;
  src: url("./Font/Montserrat/Montserrat-Light.ttf");
}

.App {
  text-align: center;
  font-family: "Montserrat";
  box-sizing: border-box;
  /* background-color: #34353a; */
}

.btnTheme {
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 10px;
  background-color: #34353a00;
  padding: 15px;
  border: none;
}

.btnTheme img {
  /* z-index: 100; */
  width: 30px;
  height: 30px;
}

.btnTheme:hover {
  cursor: pointer;
}
.appDark {
  /* background-color: #34353a; */
  background-color: var(--bg-color-dark-2);
  transition: 0.3s;
}

.appLight {
  background-color: var(--bg-color-light-2);
  color: #3d3834;
  transition: 0.3s;
}

.titleDescription {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 30vh;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 255, 255, 0);
}

.appPara {
  margin: 0;
  padding: 0;
  display: inline;
}

.appParaDark {
  color: var(--text-color-dark-grey);
}

.appParaLight {
  color: var(--text-color-light-grey);
}

.appParaSpan {
  padding: 0;
  margin: 0;
}

.appParaSpanDark {
  color: var(--text-color-dark-green);
}

.appParaSpanLight {
  color: var(--text-color-light-green);
}

.contentSection {
  width: 75%;
  margin: auto;
}

.btn {
  border-radius: 9px;
  padding: 10px;
  display: flex;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  align-items: center;
  transition-duration: 0.7s;
  font-family: "MontserratSemiBold";
}

/*Button Dark mode color scheme*/

.btnDark {
  border: 2px solid var(--text-color-dark-green);
  background-color: rgba(0, 255, 255, 0);
  color: var(--text-color-dark-green);
}

.btnDark:hover {
  background-color: var(--text-color-dark-green);
  color: #34353a;
}

.arrowDark {
  color: var(--text-color-dark-green);
  transition-duration: 0.7s;
}

.btnDark:hover .arrowDark {
  transform: rotate(90deg);
  color: #34353a;
}

/*Button LIGHT mode color scheme */
.btnLight {
  border: 2px solid var(--text-color-light-green);
  background-color: rgba(0, 255, 255, 0);
  color: var(--text-color-light-green);
}

.btnLight:hover {
  background-color: var(--text-color-light-green);
  color: #ffffff;
}

.arrowLight {
  color: var(--text-color-light-green);
  transition-duration: 0.7s;
}

.btnLight:hover .arrowLight {
  transform: rotate(90deg);
  color: #ffffff;
}

@media only screen and (max-width: 400px) {
  .btn {
    width: 160px;
    font-size: 16px;
  }
  .arrowDark,
  .arrowLight {
    font-size: 16px;
  }

  .titleDescription {
    font-size: 30px;
  }
}

@media only screen and (min-width: 401px) {
  .btn {
    width: 260px;
    font-size: 20px;
  }
  .arrowDark,
  .arrowLight {
    font-size: 20px;
  }

  .titleDescription {
    font-size: 40px;
  }
}

@media only screen and (min-width: 800px) {
  .btn {
    width: 300px;
    font-size: 22px;
  }
  .arrowDark,
  .arrowLight {
    font-size: 25px;
  }

  .titleDescription {
    font-size: 40px;
    letter-spacing: 2px;
  }
}
