.footerContainer {
  height: 100px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.lightFooter {
  background-color: var(--bg-color-light);
}

.darkFooter {
  background-color: var(--bg-color-dark);
}

.arrowImg {
  margin-top: -30px;
  width: 40px;
}

.arrowImg:hover {
  cursor: pointer;
}

.footerTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.footerText {
  font-size: 14px;
  margin: auto 0;
  margin-bottom: 30px;
}

.footerTextDark {
  color: var(--text-color-dark-grey);
}

.footerTextLight {
  color: var(--text-color-light-grey);
}
