.aboutSectionContainer {
  padding-top: 100px;
}

.aboutSection {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.aboutSection article {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-bottom: 30px;
}

.aboutSection article p {
  font-family: "MontserratMedium";
  font-size: 16px;
}

.size {
  font-size: 100px;
}

.overlap {
  position: absolute;
}

.aboutIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.color {
  margin-bottom: 5px;
  margin-top: 0px;
  font-family: "MontserratSemiBold";
}

.colorDark {
  color: var(--text-color-dark-orange);
}

.colorLight {
  color: var(--text-color-light-orange);
}

.poli {
  height: 110px;
}

.aboutWebsitesDescription {
  margin-top: 0;
}

.aboutWebsitesDescriptionDark {
  color: var(--text-color-dark-grey);
}

.aboutWebsitesDescriptionLight {
  color: var(--text-color-light-grey);
}
