.nav {
  position: sticky;
  top: 0;
  box-sizing: border-box;
  z-index: 2;
  transition: 0.3s;
}

.navDark {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark-grey);
  border-bottom: 2px solid var(--text-color-dark-green);
}

.navLight {
  background-color: var(--bg-color-light);
  color: var(--text-color-light-grey);
  border-bottom: 2px solid var(--text-color-light-green);
}

.navContent {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.navListContainers {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 58px;
  width: 100%;
}

.navList {
  display: flex;
  flex-direction: column;
}

.navList,
.social,
.logoAndBurger {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}

.logoAndBurger {
  padding: 10px 0px;
}

.navlistLi,
.socialLi {
  padding: 25px 0px;
  cursor: pointer;
}

.navlistLiDark:hover,
.socialLiDark:hover {
  color: var(--text-color-dark-green);
}

.navlistLiLight:hover,
.socialLiLight:hover {
  color: var(--text-color-light-green);
}

.imgSocial {
  width: 35px;
  padding: 20px;
}

.logo {
  width: 90px;
  color: #aab83e;
}

.activeDark {
  color: var(--text-color-dark-green);
}

.activeLight {
  color: var(--text-color-light-green);
}

.size {
  font-size: 20px;
  padding: 22px;
}

/* Burger  */

.burger {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 3px;
  margin: 8px 0;
  transition: 0.4s;
}

.barColorDark {
  background-color: var(--text-color-dark-green);
}

.barColorLight {
  background-color: var(--text-color-light-green);
}

.change .bar1 {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
}

.displ {
  display: none;
}

/* } */

@media only screen and (min-width: 801px) {
  .navContent {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .navListContainers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    background-color: #24242600;
    top: 0px;
    width: auto;
    border-bottom: none;
  }

  .navList {
    flex-direction: row;
  }

  .navContainers {
    display: flex;
  }

  .logoAndBurger {
    padding: 0px;
  }

  .navlistLi,
  .socialLi,
  .logoAndBurger li {
    padding: 0px;
  }

  .imgSocial {
    width: 30px;
    padding: 15px;
  }

  .logo {
    width: 90px;
    padding: 15px;
  }

  .size {
    font-size: 20px;
    padding: 22px;
  }

  .burger {
    display: none;
  }
}
