.aboutMeContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.imageContainer {
  margin: auto;
}

.border {
  display: flex;
  margin-top: 28px;
  height: 310px;
}

.overlap {
  position: absolute;
  margin-left: 28px;
  width: auto;
  height: 310px;
}

.aboutMeImageContainer {
  display: flex;
  justify-content: center;
}

.textContainer {
  width: 100%;

  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.textContainerH2 {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 0;
}

.textContainerH2Dark {
  color: var(--text-color-dark-orange);
}

.textContainerH2Light {
  color: var(--text-color-light-orange);
}

.textContainerP {
  display: block;
  font-size: 18px;
  font-family: "MontserratMedium";
}

.textContainerP span {
  white-space: nowrap;
}
.textContainerPDark {
  color: var(--text-color-dark-grey);
}

.textContainerPLight {
  color: var(--text-color-light-grey);
}

.languages {
  display: flex;
  flex-direction: column;
}

.btn {
  text-align: center;
  width: 220px;
  padding: 15px;
  border-radius: 10px;
  font-size: 20px;
  transition-duration: 0.7s;
  font-family: "MontserratSemiBold";
  text-decoration: none;
  letter-spacing: 1px;
}

.btnDark {
  border: 2px solid var(--text-color-dark-green);
  color: var(--text-color-dark-green);
  background-color: #aab83e00;
}

.btnLight {
  border: 2px solid var(--text-color-light-green);
  color: var(--text-color-light-green);
  background-color: #aab83e00;
}

.btnDark:hover {
  background-color: var(--text-color-dark-green);
  color: #34353a;
}
.btnLight:hover {
  background-color: var(--text-color-light-green);
  color: #ffffff;
}

.getInTouchDark {
  color: var(--text-color-dark-green);
}

.getInTouchLight {
  color: var(--text-color-light-green);
}
.getInTouch {
  white-space: nowrap;
}

.getInTouch:hover {
  cursor: pointer;
}

.colorDark {
  color: var(--text-color-dark-green);
}

.colorLight {
  color: var(--text-color-light-green);
}

@media only screen and (min-width: 700px) {
  /* .overlap {
    width: 250px;
  } */
  .languages {
    display: flex;
    flex-direction: column;
  }
  .textContainer {
    width: 80%;
  }
}

@media only screen and (min-width: 1100px) {
  .aboutMeContainer {
    flex-direction: row;
  }
  .languages {
    display: flex;
    flex-direction: row;
  }
  .border {
    display: flex;
    margin-top: 28px;
    height: 310px;
  }

  .overlap {
    position: absolute;
    margin-left: 28px;
    height: 310px;
    width: auto;
  }

  .aboutMeImageContainer {
    display: flex;
  }

  .textContainer {
    text-align: left;
    align-items: flex-start;
    justify-content: space-around;
    width: 60%;
    margin-top: 0px;
  }

  .imageContainer {
    width: 40%;
    margin-top: 0;
  }

  .btn {
    width: 200px;
  }
}
