.particles {
  /* background-color: #242426; */
  height: 100vh;
  width: 100%;
  color: #00ffff;
  transition: 0.3s;
}

.particlesDark {
  /* background-color: #242426; */
  background-color: var(--bg-color-dark);
}

.particlesLight {
  /* background-color: #e7e7e7; */
  background-color: var(--bg-color-light);
}
