.contactContainerTitle {
  font-size: 40px;
  margin-bottom: 10px;
  /* margin-top: 0px; */
}

.contactContainerTitleDark {
  color: var(--text-color-dark-orange);
}

.contactContainerTitleLight {
  color: var(--text-color-light-orange);
}

.contactCTA {
  margin: 0;
  font-size: 18px;
}

.contactCTADark {
  color: var(--text-color-dark-grey);
}

.contactCTALight {
  color: var(--text-color-light-grey);
}

.contactContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  padding-top: 70px;
}

.contactMethodsContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 80px 0px;
  width: 70%;
  max-width: 1000px;
}

.contactMethodsContainer div {
  margin: 10px 0px;
}

.mailContainer,
.phoneContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 230px;
  padding: 20px 25px;
  border-radius: 9px;
  transition: 0.3s;
}

.lightContainer {
  background-color: var(--bg-color-light);
}

.darkContainer {
  background-color: var(--bg-color-dark);
}

.mailImg,
.phoneImg {
  width: 20px;
  padding-right: 10px;
}

.mailText,
.phoneText {
  font-family: "MontserratSemiBold";
}

.mailTextDark,
.phoneTextDark {
  color: var(--text-color-dark-green);
}

.mailTextLight,
.phoneTextLight {
  color: var(--text-color-light-green);
}

.socialContainer {
  width: 70%;
  display: flex;
  justify-content: space-around;
}

.socialContainer a img {
  width: 50px;
}

.triangle {
  width: 100%;
}
@media only screen and (max-width: 550px) {
  .contactContainer h2 {
    width: 75%;
  }
  .contactCTA {
    width: 75%;
  }
}

@media only screen and (min-width: 550px) {
  .contactContainer {
    width: 75%;
  }
  .socialContainer {
    width: 50%;
  }

  .mailContainer,
  .phoneContainer {
    min-width: 300px;
  }

  .mailText,
  .phoneText {
    font-size: 20px;
    font-family: "MontserratSemiBold";
  }

  .mailImg,
  .phoneImg {
    width: 25px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .socialContainer {
    width: 30%;
  }
}
